export const SectionContact = () => {
    return (
        <div className="flex flex-col items-center"> 
            <h2>Bonjour je m'apelle nawaf</h2>
            <p>contactez-moi</p>
            <form action="">
                <label htmlFor="">Ecrivez</label>
                <input type="text" />
            </form>
        </div>
    )
}